import React, { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  decreaseQuantity,
  deleteProduct,
  increaseQuantity,
} from "../../redux/actions/cartAction";
// import Electrical from './../../images/ELECTRICAL.jpg';
// import { addProduct } from "../../redux/actions/cartAction";
import PopUp from "../commonComponents/popUp/PopUp";
import popupIcon from "../../images/popupIcon.png";
import CloseIcon from "@mui/icons-material/Close";
import "./cartitem.css";
import { LazyLoadImage } from "react-lazy-load-image-component";
import closeIcon from "../../images/icons/closeIcon.svg";
import plusIcon from "../../images/icons/plus.png";
import minusIcon from "../../images/icons/minus.png";

const CartItem = ({ productData, moq, moqDataPrice, funcFromParent }) => {
  let userDetail = useSelector((state) => state.user.userDetail);
  // const specification = productData?.poster_details;
  const productName = productData?.poster_details?.name;
  const linkName = productName?.split(" ")[0];
  const [isMoq, setIsMoq] = useState(false);

  //// for pop up
  const [popup, setPopup] = useState(false);
  const [popupMsg, setPopupMsg] = useState("");
  //// for pop up
  // for moq price
  // const moqDiscountValue = Math.floor((moqDataPrice * 5) / 100);
  // const moqDiscountPrice = moqDataPrice - moqDiscountValue;
  // for moq price
  // const [moqData, setMoqData] = useState([productData.quantity]);

  // For redirecting back to posters details
  const [link, setLink] = useState();

  ////////////////////////////
  // console.log("product data====", productData);
  let user = useSelector((state) => state);
  const dispatch = useDispatch();
  const dimension_title = productData?.materialDimension?.dimension_title ? productData?.materialDimension?.dimension_title : productData?.configuration?.dimension_title;
  let priceAfterDiscount;
  let price = productData?.total ? productData?.total : productData?.price * productData?.quantity;
  const quantity = productData?.quantity;
  const imageURL = productData?.poster_details ? productData?.poster_details?.imgUrl[0] : productData?.pageDetail ? productData?.pageDetail?.imgUrl : "mmmmm";
  const discountType = productData?.poster_details?.discount_type && productData?.poster_details?.discount_type ? productData?.poster_details?.discount_type : productData?.discount_type;
  const discountValue = productData?.poster_details?.discountValue ? productData?.poster_details?.discountValue : productData?.discountValue;
  if (discountType === 2) {
    // price = Number(priceAfterDiscount) + priceAfterDiscount * (discountValue/100);
    priceAfterDiscount = Number(price) - Number(price) * 0.05;
  } else {
    price = Number(priceAfterDiscount + discountValue);
  }
  const checkMoqIndex = productData?.index; // chechking moq index
  // const myuser = JSON.parse(localStorage.getItem("myuser"));
  useEffect(() => {
    if (linkName === "Utility" && Number(moqDataPrice) < 152) {
      setIsMoq(true);
      console.log("PRICE WISE MOQ WORKING1");
    } else if (
      linkName === "Signages" &&
      linkName === "Posters" &&
      linkName === "Asset" &&
      linkName === "Floor" &&
      Number(moqDataPrice) < 190
    ) {
      setIsMoq(true);
    } else {
      setIsMoq(false);
    }
    //  else if() {
    //   setIsMoq(false);
    // }
  }, [linkName, moqDataPrice]);
  useEffect(() => {
    // let quantits = quantity - 1;
    if (
      checkMoqIndex !== undefined &&
      checkMoqIndex !== null &&
      productData?.poster_details?.MOQ[0][checkMoqIndex] !== quantity &&
      productData?.poster_details?.MOQ[0][checkMoqIndex] > quantity
    ) {
      setIsMoq(true);
      setPopup(true);
      setPopupMsg("MOQ");
      console.log("MOQ pop up true");
    }
    // else if (
    //   userDetail?.emailid &&
    //   // checkMoqIndex !== null &&
    //   productData?.MOQ[0].length > 0
    //   // productData?.MOQ[0][checkMoqIndex] > quantity
    // ) {
    //   console.log("moq field found");
    // } else if (productData?.MOQ[0].length === 0) {
    //   console.log("NO moq field found");
    // }
    else {
      // setPopup(true);
      // setPopupMsg("MOQ");
      setIsMoq(false);
    }
  }, [
    quantity,
    checkMoqIndex,
    productData?.poster_details?.MOQ,
    linkName,
    moqDataPrice,
  ]);

  function incrementProductCount() {
    // change here: removed !
    // if (localStorage.getItem("myuser")) {
    // console.log("productData or elem after increasing the quantiy",productData)
    dispatch(
      increaseQuantity({
        // changed the _id value :
        _id: productData._id,
        poster_id: productData.poster_details._id,
        configuration: { _id: productData.materialDimension._id },
        quantity: quantity + 1,
      })
    );
    // No requirement for this as in both cases we need to increase the value of quantity.

    // } else {
    //   dispatch(
    //     addProduct({
    //       // _id: productData.poster_details._id,
    //       _id : productData._id,
    //       configuration: { _id: productData.materialDimension._id },
    //       quantity: quantity + 1,
    //     })
    //   );
    // }
  }

  function decrementProductCount() {
    let quantits = quantity - 1;
    if (moq > quantits) {
      console.log("true");
      let action = {
        _id: productData._id,
        poster_id: productData.poster_details._id,
        configuration: { _id: productData.materialDimension._id },
        quantity: quantity - 1,
        removeCart: false,
        moq: true,
        moqPosterPrice: moqDataPrice,
        moqQuantity: moq,
      };
      // if (localStorage.getItem("myuser")) {
      if (action.quantity === 0) {
        dispatch(
          decreaseQuantity({
            ...action,
            removeCart: true,
          })
        );
      } else if (isMoq) {
        console.log("Nothing updated");
      } else {
        dispatch(decreaseQuantity(action));
      }
    } else {
      console.log("false");
      let action = {
        _id: productData._id,
        poster_id: productData.poster_details._id,
        configuration: { _id: productData.materialDimension._id },
        quantity: quantity - 1,
        removeCart: false,
        moq: false,
      };
      // if (localStorage.getItem("myuser")) {
      if (action.quantity === 0) {
        dispatch(
          decreaseQuantity({
            ...action,
            removeCart: true,
          })
        );
      } else if (isMoq) {
        console.log("Nothing updated");
      } else {
        dispatch(decreaseQuantity(action));
      }
    }
  }

  const deleteItem = (id) => {
    dispatch(deleteProduct(id));
  };

  // For redirecting back to posters details
  useEffect(() => {
    if (linkName === "Posters" || linkName === "Utility") {
      setLink("posterspecification");
    } else if (linkName === "Signages" || linkName === "Asset") {
      setLink("specification");
    } else if (linkName === "Floor") {
      setLink("assetspecification");
    }
  }, [linkName]);

  // Checking MOQ Data
  // console.log(moq === quantity, "checking");
  const closePopup = () => {
    setPopup(false);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  funcFromParent(isMoq);

  // function checkMoq() {
  //   if (moq === quantity) {
  //     // setPopup(true);
  //     // setPopupMsg("MOQ");
  //     console.log("checked");
  //   }
  // }
  // Checking MOQ Data

  // console.log(imageURL)
  return (
    <>
      <div className="cartItem w-[95%] m-auto relative rounded-[10px] shadow-md">
        <div id="bigCart" className=" flex-row justify-around items-center h-[190px]">
          <div className="cartItemImg">
            <Link to={`/${link}/${productData?.poster_details?._id}`}>
              <img src={imageURL} alt="Poster" className="img" />
            </Link>
          </div>
          <div className="cartItemDetail">
            <div className="ciName">
              <Link to={`/${link}/${productData?.poster_details?._id}`} className=" no-underline text-[#636363] hover:text-blue-700 hover:underline" >
                {productData?.name ? productData?.name : productData?.poster_details?.name}
              </Link>
            </div>
            {/* <div className="ciMaterial">{productData.color === null ? "Material" : "Colour"}: <span>{productData.color === null ? productData.configuration.material_title : productData.color}</span> </div> */}
            {userDetail?.emailid ? (
              <>
                <div className="ciDimension">
                  Material:
                  <span style={{ margin: "0 0 0 10px" }}>
                    {productData?.materialDimension?.material_title}
                  </span>{" "}
                </div>
              </>
            ) : (
              ""
            )}
            <div className="ciDimension">
              Dimension:
              <span style={{ margin: "0 0 0 10px" }}>
                {dimension_title}
              </span>{" "}
            </div>
            <div className="ciPrice">
              Price:{" "}
              <span className="discountedPrice">
                ₹{Math.ceil(priceAfterDiscount)}
              </span>{" "}
              <span className="realPrice">₹{Math.ceil(price)}</span>{" "}
              {/* Add logic here to check if the discount is percentage or rupees and then display html accordingly */}
              <span className="discountPercent">
                {discountType === 2
                  ? `${discountValue}% off`
                  : `₹ ${discountValue}`}
              </span>{" "}
            </div>
          </div>
          <div className="cartItemQty">
            {/* <i
              className="fas fa-minus"
              onClick={() => {
                decrementProductCount();
                // checkMoq();
              }}
            ></i> */}
            <img
              src={minusIcon}
              alt="minus"
              className="w-4"
              onClick={() => {
                decrementProductCount();
              }}
            />
            {/* <span className="ciQty">{isMoq ? moq : quantity}</span> */}
            <span className="px-2 border-r-[1px] border-l-[1px] border-neutral-300 mx-1">
              {quantity}
            </span>
            {/* <i
              className="fas fa-plus"
              onClick={() => {
                incrementProductCount();
                // checkMoq();
              }}
            ></i> */}
            <img
              src={plusIcon}
              alt="plus"
              className="w-4"
              onClick={() => {
                incrementProductCount();
              }}
            />
          </div>
        </div>
        <div id="smallCart" className=" flex-col items-center gap-2 p-2">
          <div className="flex flex-row justify-between items-center gap-3">
            <div className="cartItemImg">
              <Link to={`/${link}/${productData?.poster_details?._id}`}>
                <img src={imageURL} alt="Poster" className="img" />
              </Link>
            </div>
            <div className="cartItemQty">
              {/* <i
                className="fas fa-minus"
                onClick={() => {
                  decrementProductCount();
                  // checkMoq();
                }}
              ></i> */}
              <img
                src={minusIcon}
                alt="minus"
                className="w-4"
                onClick={() => {
                  decrementProductCount();
                }}
              />
              {/* <span className="ciQty">{isMoq ? moq : quantity}</span> */}
              <span className="ciQty">{quantity}</span>
              {/* <i
                className="fas fa-plus"
                onClick={() => {
                  incrementProductCount();
                  // checkMoq();
                }}
              ></i> */}
              <img
                src={plusIcon}
                alt="plus"
                className="w-4"
                onClick={() => {
                  incrementProductCount();
                }}
              />
            </div>
          </div>
          <div className="max-w-[360px] mx-auto">
            <div className="cartItemDetail2 w-full flex flex-col justify-around">
              <div className="ciName">
                <Link
                  to={`/${link}/${productData?.poster_details?._id}`}
                  className=" no-underline text-[#636363] hover:text-blue-700 hover:underline"
                >
                  {productData?.name
                    ? productData?.name
                    : productData?.poster_details?.name}
                </Link>
              </div>
              {/* <div className="ciMaterial">{productData.color === null ? "Material" : "Colour"}: <span>{productData.color === null ? productData.configuration.material_title : productData.color}</span> </div> */}
              {userDetail?.emailid ? (
                <>
                  <div className="ciDimension">
                    Material:
                    <span style={{ margin: "0 0 0 10px" }}>
                      {productData?.materialDimension?.material_title}
                    </span>{" "}
                  </div>
                </>
              ) : (
                ""
              )}
              <div className="ciDimension">
                Dimension:
                <span style={{ margin: "0 0 0 10px" }}>
                  {dimension_title}
                </span>{" "}
              </div>
              <div className="ciPrice">
                Price:{" "}
                <span className="discountedPrice">
                  ₹{Math.ceil(priceAfterDiscount)}
                </span>{" "}
                <span className="realPrice">₹{Math.ceil(price)}</span>{" "}
                {/* Add logic here to check if the discount is percentage or rupees and then display html accordingly */}
                <span className="discountPercent">
                  {discountType === 2
                    ? `${discountValue}% off`
                    : `₹ ${discountValue}`}
                </span>{" "}
              </div>
            </div>
          </div>
        </div>
        {/* <div className="cartItemTotal"> ₹ {productData.price}</div> */}
        <div className="removeCartItem" onClick={() => { deleteItem(productData._id) }} >
          <img src={closeIcon} alt="Close" className="w-[20px]" />
        </div>
        {popupMsg === "MOQ" && (
          <PopUp popup={popup}>
            <div className="materialPopup">
              <LazyLoadImage src={popupIcon} alt="select configuration" />
              <div className="popupDetails">
                <span className="popupH">Order quantity</span>
                <span className="popupT">
                  Minimum quantity required:{" "}
                  {productData?.poster_details?.MOQ[0][checkMoqIndex]}
                </span>
              </div>
              <CloseIcon className="closePopup" onClick={closePopup} />
            </div>
          </PopUp>
        )}
      </div>
    </>
  );
};

export default CartItem;
