import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// import covidpic from "../../../images/COVID.png";
import { deleteProduct } from "../../../redux/actions/cartAction";
import closeIcon from '../../../images/icons/closeIcon.svg';

const OrderComp = ({
  productData,
  qty,
  price,
  img,
  desc,
  name,
  total,
  dimension,
  material,
  cartType //checking either "EHS" or "DIY"
}) => {
  const discount = Math.floor(price / 20);
  const dispatch = useDispatch();

  const deleteItem = (id) => {
    dispatch(deleteProduct(id));
  };
  return (
    <>
      <div className="item flex-col md:flex-row relative">
        <img src={img} alt="Ehs poster" className=" max-w-[160px]" />
        <div className="product-detail my-auto text-sm">
          <p className="name mb-1 text-base md:text-xl">{name}</p>
          <p className="qty mb-1"><span className="font-bold">Material:</span> {material} </p>
          {
            (cartType === "ehs") ?
              <p className="qty mb-1"><span className="font-bold">Dimension:</span> {dimension} </p>
              :
              <p className="qty mb-1"><span className="font-bold">Dimension:</span> {dimension} inch² </p>
          }
          <p className="qty mb-1"><span className="font-bold">Quantity :</span> <span>{qty}</span> </p>
          {
            (cartType === "ehs") ?
              <div>
                <p className="price mb-1">
                  <span className="font-bold">Price :</span>
                  <span> ₹{(price - discount) * qty}</span>
                  <span style={{ fontSize: "0.9rem", fontWeight: "500", color: "#636363", textDecoration: "line-through" }}>
                    {"  "} ₹{price * qty}
                  </span>
                  <span style={{ fontSize: "0.9rem", fontWeight: "400", color: "#f2994a", }} >
                    {" "}5% off
                  </span>
                </p>
                <p className="disc mb-1">
                  <span className="font-bold">Total :</span>
                  <span> ₹{(price - discount) * qty}</span>
                </p>
              </div> :
              <div>
                <p className="disc mb-1">
                  <span className="font-bold">Price :</span>
                  <span> ₹{(price)}</span>
                </p>
              </div>
          }
        </div>
        <div className="cursor-pointer" onClick={() => { deleteItem(productData._id); }} >
          <img src={closeIcon} alt="Close" className="w-[20px]" />
        </div>
      </div>
    </>
  );
};

export default OrderComp;
